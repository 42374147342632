export default {
	"viewClaim.title": "Schadensfall einsehen",
	"viewClaim.search.label":
		"Hier können Sie den Bearbeitungsstand Ihres Schadenfalls einsehen",
	"viewClaim.search.placeholder": "Schadencode eingeben",
	"viewClaim.search.delete": "Suchbegriff löschen",
	"viewClaim.contact.title": "Sie haben weitere Fragen zu Ihrem Schadenfall?",
	"viewClaim.contact.subtitle":
		"Gerne helfen wir Ihnen weiter! Sie erreichen uns wie folgt:",
	"viewClaim.contact.phoneNumber": "Telefon: {phoneNumber}",
	"viewClaim.info.title": "Detaillierter Schadensverlauf",
	"viewClaim.info.date": "Datum: ",
	"viewClaim.search.notFound":
		"Keine Schadenmeldungen für diese Versicherung gefunden.",
	"viewClaim.status.description.abgeschlossen": "Abgeschlossen",
	"viewClaim.status.description.abgelehnt": "Abgelehnt",
	"viewClaim.status.description.wartenAufKundenantwort":
		"Warten auf Kundenantwort",
	"viewClaim.status.description.inBearbeitung": "In Bearbeitung",
	"viewClaim.status.description.schadenfall": "Schadenfall",
	"viewClaim.status.description.unbekannt": "Unbekannt",
	"viewClaim.status.label.abgeschlossen": "Abgeschlossen",
	"viewClaim.status.label.abgelehnt": "Abgelehnt",
	"viewClaim.status.label.wartenAufKundenantwort": "Warten auf Kundenantwort",
	"viewClaim.status.label.inBearbeitung": "In Bearbeitung",
	"viewClaim.status.label.schadenfall": "Schadenfall",
	"viewClaim.status.label.unbekannt": "Unbekannt",
	"viewClaim.search.numberNotFound":
		"Leider konnten wir den Code nicht finden. Bitte beachten Sie, dass wir Ihnen die Nummer per E-Mail gesendet haben.",
} as const;
