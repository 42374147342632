const cancelContact = {
	"cancelPolicy.contact.text":
		"Wenn Sie Fragen haben sollten, dann melden Sie sich gerne unter:",
	"cancelPolicy.contact.phone": "Tel: + 49 40 35914 504",
	"cancelPolicy.contact.email":
		"E-Mail: <email>heilwesen@funk-experts.de</email>",
	"cancelPolicy.contact.business.text":
		"Wenn Sie Fragen haben sollten, dann melden Sie sich gerne unter:",
	"cancelPolicy.contact.business.phone": "Tel: + 49 40 35914 504",
	"cancelPolicy.contact.business.email":
		"E-Mail: <email>heilwesen@funk-experts.de</email>",
};

export default {
	...cancelContact,
	"cancelPolicy.title": "Police kündigen",
	"cancelPolicy.form.title": "Sie möchten Ihre Police kündigen?",
	"cancelPolicy.text":
		'Dann drücken Sie einfach auf den Button "Police kündigen" und wir werden Ihre Anfrage bearbeiten. Sie erhalten von uns in wenigen Tagen eine Kündigungs-Bestätigung per E-Mail.',
	"cancelPolicy.feedback.title": "Warum wollen Sie kündigen?",
	"cancelPolicy.feedback.option.1": "Zu teuer",
	"cancelPolicy.feedback.option.2": "Leistungen fehlen",
	"cancelPolicy.feedback.option.3": "Berufliche Umorientierung",
	"cancelPolicy.feedback.option.4": "Sonstiges",
	"cancelPolicy.feedback.text.label": "Bitte erläutern Sie dies näher",
	"cancelPolicy.feedback.text.placeholder": "Bitte hier schreiben",
	"cancelPolicy.confirmation":
		"Ich bestätige hiermit, dass ich meine Police unwiderruflich kündigen möchte.",
	"cancelPolicy.submit": "Police kündigen",
	"cancelPolicy.success.title":
		"Die Kündigung Ihrer Versicherung haben wir erhalten",
	"cancelPolicy.success.text":
		"Sie erhalten eine Bestätigung mit weiteren Infos per E-Mail.",
	"cancelPolicy.error":
		"Es trat ein Fehler auf, bitte versuchen Sie es noch einmal.",
} as const;
