export default {
	"withdrawPolicy.title": "Police widerrufen",
	"withdrawPolicy.form.title": "Sie möchten Ihre Police widerrufen?",
	"withdrawPolicy.text":
		'Dann drücken Sie einfach auf den Button "Police widerrufen" und wir werden Ihre Anfrage bearbeiten. Sie erhalten von uns in wenigen Tagen eine Kündigungs-Bestätigung per E-Mail.',
	"withdrawPolicy.feedback.title": "Warum wollen Sie kündigen?",
	"withdrawPolicy.feedback.text.label": "Bitte erläutern Sie dies näher",
	"withdrawPolicy.feedback.text.placeholder": "Bitte hier schreiben",
	"withdrawPolicy.confirmation":
		"Ich bestätige hiermit, dass ich meine Police unwiderruflich kündigen möchte.",
	"withdrawPolicy.submit": "Police widerrufen",
	"withdrawPolicy.success.title":
		"Den Widerruf Ihrer Versicherung haben wir erhalten",
	"withdrawPolicy.success.text":
		"Sie erhalten eine Bestätigung mit weiteren Infos per E-Mail.",
	"withdrawPolicy.error":
		"Es trat ein Fehler auf, bitte versuchen Sie es noch einmal.",
} as const;
