export default {
	"premiumCalc.urlSlug": "tarifrechner",
	"premiumCalc.calculate": "Jetzt Tarif berechnen",
	"premiumCalc.title": "Tarifrechner",
	"premiumCalc.targetGroup": "Berufsbezeichnung",
	"premiumCalc.targetGroup.placeholder": "Wie lautet Ihre Berufsbezeichnung?",
	"premiumCalc.insuranceProduct": "Versicherung",
	"premiumCalc.insuranceProduct.placeholder": "Bitte wählen Sie ein Produkt",
	"premiumCalc.legal":
		"Ich bestätige, dass ich die {mandatoryInformation} gelesen habe und stimme ihnen zu. ",
	"premiumCalc.mandatoryInformation": "Pflichtinformationen",
	"premiumCalc.disabled.title":
		"Dieses Produkt ist vorübergehend nicht verfügbar.",
	"premiumCalc.disabled.text":
		"Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.",
} as const;
