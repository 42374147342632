export default {
	"context.menu.delete": "Löschen",
	"context.menu.edit": "Bearbeiten",
	"context.menu.altered": "Angebot annehmen",
	"context.menu.applyForInsurance": "Versicherung beantragen",
	"context.menu.applyForMultipleInsurances": "Versicherungen beantragen",
	"context.menu.missingPolicy":
		"Keine entsprechende Versicherung gefunden.\nBitte wenden Sie sich an FUNK.",
	"context.menu.error": "Versicherung konnte nicht angelegt werden",
	"context.menu.errorPlural":
		"{count} Versicherung{count, plural, =1 {} other {en}} konnte{count, plural, =1 {} other {n}} nicht angelegt werden",
	"context.menu.success": "Versicherung wurde angelegt",
	"context.menu.successPlural":
		"{count} Versicherung{count, plural, =1 {} other {en}} wurde{count, plural, =1 {} other {n}} angelegt",
	"context.menu.selectAction": "Aktion auswählen",
	"context.menu.label": "Optionen",
	"context.menu.alertDialog.submitLeadHeader":
		"Mit Klick auf „Ja“ bestätigen Sie, dass Sie alle versicherungsrelevanten Dokumente zu allen ausgewählten Objekten, die Sie policieren lassen wollen, gelesen haben.",
	"context.menu.alertDialog.submitLeadDescription": `
		<p>
			Sie finden die Dokumente, wenn Sie in der Liste auf der 
			<link>Übersichtsseite des Maklerportals</link> 
			die Details zu den jeweiligen Objekten aufklappen.
		</p>
		<p>
			<strong>Durch das Betätigen des Buttons „Ja“ beantragen Sie verbindlich den Versicherungsvertrag.</strong> 
			Der Vertrag kommt erst mit der Annahme des Versicherers zustande. Sollte der Antrag nicht angenommen werden, wird der Status entsprechend gekennzeichnet.
		</p>
		<p>
			Indem Sie die IBAN der Versicherungsnehmer*in angeben, ermächtigen Sie Funk Experts und unseren Zahlungsdienstleister Stripe, die zu entrichtende Zahlung bei Fälligkeit von dem Konto der Versicherungsnehmer*in abzubuchen. Sie bestätigen, dass Sie die hierzu erforderliche Vollmacht haben.
		</p>`,
	"context.menu.alertDialog.submitLeadDescription.url": "/broker-portal",
	"context.menu.selectOneStatus":
		"Bitte wählen Sie nur Zeilen mit demselben Status aus.",
} as const;
