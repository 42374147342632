export default {
	"blog.urlSlug": "blog",
	"blogpost.readMore": "Weiterlesen",
	"blogpost.seeMore": "Mehr anzeigen",
	"blogpost.seeLess": "Weniger anzeigen",
	"blogpost.date": "Datum: {date}",
	"blogpost.readingTime": "Lesezeit: {readingTime} Minute{count}",
	"blogpost.topic": "Thema:",
	"blogpost.author": "Von {name}",
	"blogpost.allTopics.name": "Alle Themen",
	"blogpost.allTopics.slug": "all",
	"blogpost.chooseTopic": "Bitte wählen Sie ein Thema:",
	"blogpost.share": "Diesen Artikel teilen",
} as const;
