export default {
	"portal.actions.createClaim": "Schaden melden",
	"portal.actions.viewClaim": "Schaden einsehen",
	"portal.actions.viewDetails": "Details einsehen",
	"portal.actions.withdrawPolicy": "Police widerrufen",
	"portal.actions.cancelPolicy": "Police kündigen",
	"portal.actions.updatePolicy": "Versicherung anpassen",
	"portal.actions.rebookPolicy": "Versicherung wieder abschließen",
	"portal.actions.start": "Beginn: ",
	"portal.actions.willEndAt": "Endet am ",
	"portal.actions.hasEndedAt": "Endete am ",
	"portal.actions.updatePaymentInterval": "Zahlweise ändern",
	"policyStatus.withdrawn": "Diese Police wurde widerrufen.",
	"policyStatus.terminated": "Diese Police wurde gekündigt.",
	"policyStatus.suspended": "Diese Police wurde aufgelöst.",
	"portal.actions.updatePaymentMethod": "Zahlungsmethode ändern",
} as const;
