export default {
	"statustag.tooltip.trigger": "Status",
	"statustag.approved.label": "Aktiv",
	"statustag.approved.description":
		"Der Vertrag wurde genehmigt und ist versichert.",
	"statustag.draft.label": "Entwurf",
	"statustag.draft.description":
		"Der Vertrag befindet sich im Entwurf und ist noch nicht versichert.",
	"statustag.rejected.label": "Abgelehnt",
	"statustag.rejected.description":
		"Der Vertrag wurde aufgrund von fehlenden Informationen abgelehnt.",
	"statustag.processing.label": "In Prüfung",
	"statustag.processing.description":
		"Der Vertrag wird derzeit von unserem Team bearbeitet.",
	"statustag.altered.label": "Angebot annehmen",
	"statustag.altered.description":
		"Der Antrag wurde von unserem Team bearbeitet und angepasst. Dieser muss von Ihnen bestätigt werden.",
	"statustag.terminated.label": "Gekündigt",
	"statustag.terminated.description":
		"Der Vertrag wurde widerrufen oder gekündigt.",
	"statustag.custom.label": "Benutzerdefiniert",
	// 🥦 TODO Is this what we actually mean by "Tenant User"?
	"statustag.custom.description":
		"Ein benutzerdefinierter Status, der vom Immobilienverwalter festgelegt wird.",
	"statustag.unknown.label": "Unbekannt",
	// 🥦 TODO speak about this case w/ Lea
	"statustag.unknown.description":
		"Der Status dieser Versicherung ist unbekannt. Bitte kontaktieren Sie FUNK, wenn dies weiterhin der Fall ist.",
} as const;
