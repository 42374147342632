export default {
	"updatePaymentInterval.title": "Zahlweise ändern",
	"updatePaymentInterval.paymentInterval.label": "Zahlweise",
	"updatePaymentInterval.paymentInterval.option.yearly": "Jährlich",
	"updatePaymentInterval.paymentInterval.option.monthly": "Monatlich",
	"updatePaymentInterval.submit.label": "Zahlweise ändern",
	"updatePaymentInterval.success.title": "Zahlweise geändert",
	"updatePaymentInterval.success.text":
		"Ihre Zahlweise wurde geändert. Sie erhalten eine Bestätigung als E-Mail. Vielen Dank für Ihr Vertrauen.",
} as const;
