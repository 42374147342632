const emailSubjects = {
	"contact.subject.email": "Kontaktformular",
	"contact.subject.callback": "Rückruf erbeten",
	"contact.subject.demo": "Demo-Anfrage",
};

const formFields = {
	"contact.form.date.label": "Datum",
	"contact.form.date.placeholder": "Am...",
	"contact.form.timeSlot.label": "Zeitfenster",
	"contact.form.timeSlot.help": "Zeitzone Berlin",
	"contact.form.telephone.label": "Ihre Telefonnumer",
	"contact.form.telephone.placeholder": "Ihre Telefonnumer",
	"contact.form.telephone.help.text": "Telefonnummernformat:",
	"contact.form.telephone.help.countryCode": "(Ländervorwahl) +49",
	"contact.form.telephone.help.number": "148562xxxx",
	"contact.form.name.label": "Vor- und Nachname",
	"contact.form.name.placeholder": "Vor- und Nachname",
	"contact.form.email.label": "E-Mail Adresse",
	"contact.form.email.placeholder": "E-Mail Adresse",
	"contact.form.message.label": "Ihre Nachricht",
	"contact.form.message.label.optional": "Ihre Nachricht",
	"contact.form.message.placeholder": "Ihre Nachricht",
	"contact.form.company.label": "Für welches Unternehmen arbeiten Sie?",
	"contact.form.company.placeholder": "Unternehmen",
	"contact.form.targetGroupSlug.label": "Berufsbezeichnung",
	"contact.form.targetGroupSlug.placeholder":
		"Wie lautet Ihre Berufsbezeichnung?",
	"contact.form.insuranceProduct.label": "Versicherung",
	"contact.form.insuranceProduct.placeholder": "Bitte wählen Sie ein Produkt",
};

export default {
	"contact.callbackService": "Rückrufservice",
	"contact.email": "E-Mail",
	"contact.send": "Senden",
	"contact.userAvailable": "Wann sind Sie gut erreichbar?",
	"contact.demo.title": "Demo testen",
	"contact.consent":
		"Bei Ihrer Kontaktaufnahme mit uns werden die von Ihnen mitgeteilten Daten von uns verarbeitet, um Ihre Fragen zu beantworten. Wir löschen Ihre Daten, soweit der Zweck erfüllt ist und keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Weitere Hinweise entnehmen Sie bitte der Datenschutzerklärung.",
	...formFields,
	...emailSubjects,
} as const;
