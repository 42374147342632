import { useBreakpointValue } from "@chakra-ui/react";
import { useCallback, useEffect, useState, useMemo } from "react";
import { IntersectionOptions, useInView } from "react-intersection-observer";
import { useIntl } from "react-intl";
import { Tag } from "src/__generated__/cms-schema.codegen";
import { Role } from "src/components/AuthGuard/AuthGuard";
import { getBaseUrl } from "./deployments";
import { createContext } from "./react";
import {
	maskCdnUrl,
	PortalType,
	ProductSlug,
	SiteSettings,
	TargetGroup,
	Blogpost,
	Products,
	OnboardingType,
} from "./ssr/utils";

export const useLazyToggle = (
	options: IntersectionOptions,
): [(node?: Element | null) => void, boolean] => {
	const [lazyLoaded, set] = useState(false);
	const { ref, inView } = useInView(options);

	useEffect(() => {
		if (!lazyLoaded && inView) {
			set(true);
		}
	}, [inView, lazyLoaded]);

	return [ref, lazyLoaded];
};

export const useCurrencyFormatter = () => {
	const intl = useIntl();

	return useCallback(
		(value: number, currency = "EUR") =>
			intl.formatNumber(value, {
				style: "currency",
				currency,
			}),
		[intl],
	);
};

export type CurrencyFormatter = ReturnType<typeof useCurrencyFormatter>;

export const [SiteSettingsContext, useSiteSettings] =
	createContext<SiteSettings>("SiteSettingsContext");

export const [TargetGroupContext, useTargetGroup] =
	createContext<TargetGroup>("TargetGroupContext");

export const [ProductSlugContext, useProductSlug] =
	createContext<ProductSlug>("ProductSlugContext");

export const [BlogpostContext, useBlogpostContext] =
	createContext<Blogpost>("BlogpostContext");

export const [ProductsContext, useProducts] =
	createContext<Products>("ProductContext");

export const [RoleContext, useRole] = createContext<Role | null>("RoleContext");

// Reveal the portal a user is currently in, since checking their role
//  is not enough when a broker should be able to visit the customer portal.
export const [PortalTypeContext, usePortalType] =
	createContext<PortalType>("PortalTypeContext");

export const [OnboardingSlidesContext, useOnboardingSlides] =
	createContext<OnboardingType>("OnboardingSlidesContext");

export const useEnrichedMetaTags = (tags: Array<Tag>) => {
	const { brandConfig } = useSiteSettings();

	return useMemo(
		() =>
			tags.map((tag) => ({
				...tag,
				content: tag.content
					? maskCdnUrl(tag.content, getBaseUrl(brandConfig.slug))
					: tag.content,
			})),
		[brandConfig.slug, tags],
	);
};

export const usePagination = ({
	itemsPerPage,
	itemsTotal,
	breakpoint,
	overwriteMobileRule,
	cutoffThreshold = 0,
}: {
	itemsTotal: number;
	breakpoint: string;
	itemsPerPage?: number;
	overwriteMobileRule?: boolean;
	cutoffThreshold?: number;
}) => {
	const [page, setPage] = useState(1);

	const isMobile = useBreakpointValue(
		{ base: true, [breakpoint]: false },
		{ fallback: breakpoint, ssr: true },
	);

	const limit = isMobile
		? itemsPerPage
		: overwriteMobileRule
			? itemsPerPage
			: undefined;

	// e. g. 1 page * 3 itemsPerPage + 2 cutoffThreshold > 5 itemsTotal = false, use Pagination as usual
	// e. g. 1 page * 3 itemsPerPage + 2 cutoffThreshold > 4 itemsTotal = true, so append the remaining items to the last page
	const useCutoffThreshold = itemsPerPage
		? page * itemsPerPage + cutoffThreshold > itemsTotal
		: false;

	const maxPage =
		useCutoffThreshold && limit
			? Math.ceil((itemsTotal - cutoffThreshold) / limit)
			: limit
				? Math.ceil(itemsTotal / limit)
				: 1;

	const sliceItems =
		useCutoffThreshold && page >= maxPage
			? itemsTotal
			: limit
				? Math.min(itemsTotal, page * limit)
				: itemsTotal;

	const onShowNext = () => setPage((i) => (i >= maxPage ? i : i + 1));
	const onShowLess = () => setPage(1);
	const remainingItems = itemsTotal - sliceItems;
	const hasMore = remainingItems > 0;

	return {
		hasMore,
		isMobile,
		sliceItems,
		onShowNext,
		onShowLess,
		remainingItems,
	};
};

// 🔬 RTL
