import {
	Context,
	useContext as useContextReact,
	createContext as reactCreateContext,
} from "react";

/**
 * create react context and also return an appropriate useFooContext
 * hook with the provider error, if it's not provided.
 */
export const createContext = <T>(
	name: string,
): [Context<T | undefined>, (fallback?: T) => T] => {
	const ctx = reactCreateContext<T | undefined>(undefined);

	ctx.displayName = name;

	const useContext = (fallback?: T) => {
		const value = useContextReact(ctx);

		if (value === undefined) {
			if (fallback) {
				return fallback;
			}

			throw new Error(`Context ${name} not provided?`);
		}

		return value;
	};

	return [ctx, useContext];
};

// 🔬 TBD: Please evaluate
