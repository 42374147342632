export default {
	"login.email": "E-Mail-Adresse",
	"login.password": "Kennwort",
	"login.loggedOut": "Sie wurden erfolgreich abgemeldet!",
	"login.newUser.beforeLink": "Neu hier?",
	"login.newUser.link":
		"Berechnen Sie jetzt Ihren tarif und werden Mitglied.",
	"login.2fa": `Wenn Sie auf "Anmelden" klicken, dann erhalten Sie gemäß der Zahlungsrichtlinien einen Code per SMS für die 2-Faktor-Authentifzierung.`,
	"login.forgotPasswordMessage":
		"Kennwort vergessen? Bitte<link>hier</link>klicken.",
	"login.privacy":
		"Bitte beachten Sie unsere <link>Datenschutzhinweise</link>.",
	"login.privacy.href": "datenschutz",
	"login.failed": "E-Mail oder Passwort ungültig",
	"login.type.customer": "Kundenportal",
	"login.type.broker": "Maklerportal",
	"login.type.customer.description":
		"Das Portal richtet sich an Endkunden, die keinen Bezug zur Unternehmenswelt haben.",
	"login.type.broker.description":
		"Das Portal wurde für unsere Immobilien- und Gewerbekunden erstellt.",
	"twoFactor.title": "Authentifizierung erforderlich",
	"twoFactor.label": "SMS Code",
	"twoFactor.error":
		"Der eingegebene Code konnte leider nicht bestätigt werden. Bitte versuchen Sie es noch einmal.",
	"twoFactor.content":
		"<p>Im Rahmen der überarbeiteten Zahlungsdiensterichtlinie (PSD2) „Starke Kundenauthentifizierung“ benötigen wir weitere Informationen, die bestätigen, dass es sich wirklich um Sie handelt.</p>",
	"twoFactor.retry": "Erneut senden",
} as const;
