export default {
	"forgotPassword.success": "Die E-Mail wurde gesendet!",
	"forgotPassword.content":
		"<p>Geben Sie die E-Mail-Adresse ein, die Sie bei Ihrem Beitritt verwendet haben, und wir senden Ihnen Anweisungen zum Zurücksetzen Ihres Passworts. hier? </p><p>Aus Sicherheitsgründen speichern wir Ihr Passwort NICHT. Sie können also sicher sein, dass wir Ihnen Ihr Passwort niemals per E-Mail zusenden werden.</p>",
	"forgotPassword.content.success":
		"<p>Sie erhalten in Kürze eine E-Mail mit Anweisungen, wie Sie Ihr Passwort zurücksetzen können.</p><p>Bitte überprüfen Sie Ihren E-Mail-Posteingang oder Ihren Spam-Ordner.</p>",
	"forgotPassword.retry": "Haben Sie die E-Mail nicht erhalten?",
	"forgotPassword.retry.link": "E-Mail erneut senden",
	"forgotPassword.error":
		"Ihr Passwort konnte nicht zurückgesetzt werden. Bitte versuchen Sie es noch einmal.",
	"forgotPassword.submit.label": "Abschicken",
	resetPassword: "Kennwort vergessen?",
	"register.newUser.link": "Jetzt registrieren!",
	"register.newUser.beforeLink": "Neu hier?",
} as const;
