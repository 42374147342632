export default {
	"spreadsheetUpload.title":
		"Bitte lesen Sie die folgenden Informationen, bevor Sie diesen Service nutzen",
	"spreadsheetUpload.description":
		"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. Mit dem Absenden dieser Informationen stimme ich den Datenschutz zu.",
	"spreadsheetUpload.legal":
		"Bitte beachten Sie, dass Sie zu wahrheitsgemäßen Angaben verpflichtet sind. Weichen Ihre Angaben von Tatsachen ab, besteht kein Versicherungsschutz. Die Versicherungsbedingungen finden Sie <link>hier</link>.",
	"spreadsheetUpload.legal.href":
		"cdn/75571/1706098093-versicherungsbedingungen_iak.pdf",
	"spreadsheetUpload.step1.title": "Vorlage herunterladen",
	"spreadsheetUpload.step2.title": "Das Dokument hochladen",
	"spreadsheetUpload.step1.instructions":
		"Laden Sie die folgende Excel-Datei herunter und füllen Sie sie aus, ohne Spaltenüberschriften hinzuzufügen oder umzubenennen.",
	"spreadsheetUpload.step2.instructions":
		"Nur .xlsx, .xls und .csv Dateien werden akzeptiert.",
	"spreadsheetUpload.continue": "Weiter",
	"spreadsheetUpload.back": "Zurück",
	"spreadsheetUpload.download": "Excel-Datei herunterladen",
	"spreadsheetUpload.result.header": "Ergebnisse Ihres Uploads",
	"spreadsheetUpload.result.body.successes":
		"Die folgenden Einträge wurden erfolgreich angelegt:",
	"spreadsheetUpload.result.body.errors":
		"Die folgenden Einträge konnten nicht verarbeitet werden:",
	"spreadsheetUpload.result.close": "Schließen",
	"spreadsheetUpload.icon": "Spreadsheet icon",
} as const;
