type DeploymentType =
	| "production"
	| "testing"
	| "preview"
	| "localhost"
	| undefined;

export const identifyDeployment = (): DeploymentType => {
	const vercelUrl = process.env.VERCEL_URL;
	const vercelEnv = process.env.VERCEL_ENV;

	if (!vercelUrl) {
		return "localhost";
	}

	if (vercelUrl.startsWith("testing")) {
		return vercelEnv === "production" ? "testing" : "preview";
	}

	if (vercelUrl.startsWith("production")) {
		return "production";
	}

	return undefined;
};

export const getBaseUrl = (brandSlug: string): string => {
	if (identifyDeployment() === "preview") {
		return process.env.VERCEL_URL_PREDICTED ?? "http://localhost:3000";
	}
	const template = process.env.BASE_URL_TEMPLATE;

	if (!template) {
		throw new Error("BASE_URL_TEMPLATE missing");
	}

	return template.replace("{brand}", brandSlug);
};

// 🔬 unit tested
