export default {
	"createClaim.broker.title": "Melden Sie Ihren Schaden hier online",
	"createClaim.customer.title": "Schaden melden",
	"createClaim.send": "So melden Sie Ihren Schaden",
	"createClaim.datetime.title": "Wann ist Ihr Schaden entstanden?",
	"createClaim.yes": "Ja",
	"createClaim.no": "Nein",
	"createClaim.requiredField": "*Pflichtfeld",
	"createClaim.details.placeholder": "Bitte hier schreiben",
	"createClaim.broker.note":
		"Hier können Sie in wenigen Schritten einen entstandenen <em>Schaden</em> melden.  Wir kümmern uns um eine schnelle Bearbeitung. Bitte ergänzen Sie folgende Angaben.",
	"createClaim.loading": "Gleich ist es geschafft!",
	"createClaim.customer.note":
		"Bitte melden Sie den <em>Schaden</em> unverzüglich. Wir helfen Ihnen schnellstmöglich um eine umgehende Bearbeitung sicherstellen können.",
	// success
	"createClaim.success.title": "Ihr Schaden wurde erfolgreich gemeldet",
	"createClaim.success.claimNumber": "Ihre Schadensnummer lautet: {number}",
	"createClaim.success.portal": "Zum Kundenportal",
	"createClaim.success.phone.href": "tel:00494035914504",
	"createClaim.success.phone.label": "Tel.: + 49 40 35914 504",
	"createClaim.success.email.href": "mailto:heilwesen@funk-experts.de",
	"createClaim.success.email.label": "E-Mail: heilwesen@funk-experts.de",
	"createClaim.success.text": `
		<wrapper>
			<title>Was ist nun der nächste Schritt?</title>
			{divider}
			<p>Unsere qualifizierten Jurist*innen überprüfen Ihre Angaben und übermitteln den Schaden an den Versicherer. Wenn Sie Fragen haben sollten, dann melden Sie sich gerne unter:</p>
			{links}
		</wrapper>`,
	// type select
	"createClaim.type.question": "Wofür möchten Sie einen Schaden melden?",
	"createClaim.type.verwalteteEinheit": "Verwaltete Einheit beschädigt?",
	"createClaim.type.hug":
		"Eigentum/Leben/Gesundheit eines Mieters oder Dritten beschädigt?",
	"createClaim.claimNumber.label":
		"Wenn Sie eine interne Schadensnummer haben, geben Sie diese bitte hier ein.",
	"createClaim.claimNumber.placeholder":
		"Wenn Sie eine interne Schadensnummer haben, geben Sie diese bitte hier ein.",
	"createClaim.claimNumber.error.duplicate":
		"Dieser Schaden existiert bereits.",
	// contact data
	"createClaim.contact.title": "Kontaktangaben",
	"createClaim.contact.title.reporter": "Kontaktdaten des Melders",
	"createClaim.contact.title.pointOfContact":
		"Kontaktdaten des Ansprechpartners vor Ort",
	"createClaim.contact.name": "Vor- und Nachname",
	"createClaim.contact.email": "E-Mail-Adresse",
	"createClaim.contact.email.required": "E-Mail-Adresse*",
	"createClaim.contact.phone": "Rufnummer",
	"createClaim.contact.pointOfContact.label":
		"Kontaktdaten des Ansprechpartners vor Ort",
	"createClaim.contact.pointOfContact.reporter": "Wie der Melder",
	"createClaim.contact.pointOfContact.localContact": "abweichend",
	// claim
	"createClaim.claim.location.country": "Deutschland",
	"createClaim.claim.title": "Angabe zum Schaden",
	"createClaim.claim.datetime.title": "Wann ist der Schaden entstanden?",
	"createClaim.claim.datetime.date": "Datum",
	"createClaim.claim.datetime.time": "Uhrzeit",
	"createClaim.claim.datetime.time.helperText": "Zeitzone Berlin",
	"createClaim.claim.location.title": "Schadenort",
	"createClaim.claim.location.address.label": "Anschrift",
	"createClaim.claim.location.address.helperText":
		"Adressformat: Straße und Hausnummer, PLZ, Ort",
	"createClaim.claim.location.address.placeholder":
		"Straße und Hausnummer, PLZ, Ort",
	"createClaim.claim.location.country.label": "Land",
	"createClaim.claim.location.area.label": "Bereich",
	"createClaim.claim.location.area.placeholder": "z. B.: Keller, EG, 1 OG.",
	"createClaim.claim.claimInfo.title": "Schadenart und Ursache Auswahl",
	"createClaim.claim.claimInfo.type.label": "Schadenart",
	"createClaim.select": "Bitte wählen Sie eine Option",
	"createClaim.claim.claimInfo.claimReason.label": "Schadenursache",
	"createClaim.claim.claimInfo.cause.title": "Schadenhergang",
	"createClaim.claim.claimInfo.cause.label":
		"Um Ihnen den besten Service bieten zu können: Bitte seien Sie so spezifisch wie möglich.",
	"createClaim.claim.claimInfo.textArea.placeholder":
		"Bitte hier mehr als ein Wort schreiben",
	"createClaim.claim.claimInfo.amount.title":
		"Voraussichtliche Gesamtschadenhöhe erforderlich",
	"createClaim.claim.claimInfo.amount.label":
		"Bitte geben Sie die Gesamtschadenhöhe ohne Nachkommastellen an.",
	"createClaim.claim.claimInfo.amount.placeholder": "5.000,00 €",
	"createClaim.claim.claimInfo.grossAmount.label":
		"Bitte geben Sie den endgültigen brutto Erstattungsbetrag für den eingetretenen Schaden an.",
	"createClaim.claim.claimInfo.amount.error":
		"Bitte geben Sie eine Zahl ein.",
	"createClaim.claim.claimInfo.consequentialDamage.label":
		"Folgeschaden zu erwarten?",
	"createClaim.claim.claimInfo.isClosed.label":
		"Schaden mit dieser Meldung abgeschlossen?",
	"createClaim.claim.claimInfo.upload.title":
		"Wenn Sie Datei hochladen möchten, geht das hier:",
	"createClaim.claim.claimInfo.upload.helperText":
		"Sie können Fotos, Dokumente, Rechnungen, KVA oder anderes hochladen.",
	"createClaim.claim.claimInfo.uploadType.label": "Dateityp zum Hochladen",
	"createClaim.claim.claimInfo.upload.label": "Datei hochladen",
	"createClaim.claim.claimInfo.upload.restrictions": `
		<div>
			<p>Zulässige Anzahl: 9 Dateien pro Upload-Vorgang</p>
			<p>Zulässige Dateiformate: JPG, PNG, PDF, TXT</p>
			<p>Maximale Größe: 5 MB pro Datei, insgesamt max. 19,5 MB</p>
		</div>`,
	"createClaim.claim.claimInfo.upload.acceptedFiles":
		"Es werden nur JPG, PNG, PDF sowie TXT Dateien akzeptiert.",
	"createClaim.claim.claimInfo.upload.limit":
		"Bitte laden Sie maximal 9 Dateien hoch.",
	"createClaim.claim.claimInfo.upload.fileSize":
		"Die Datei ist zu groß. Bitte wählen Sie eine Datei mit einer maximalen Größe von 5 MB.",
	"createClaim.claim.claimInfo.upload.allFileSizes":
		"Die Dateien sind zu groß. Bitte beschränken Sie die Größe der hochgeladenen Dateien auf 19,5 MB.",
	"createClaim.claim.claimInfo.upload.acceptedTypes":
		"Bitte wählen Sie einen Dateityp zum Hochladen aus.",
	"createClaim.claim.claimInfo.upload.repetition":
		"Diese Datei wurde bereits hochgeladen.",
	"createClaim.claim.claimInfo.upload.deleteFile": "Datei löschen",
	"createClaim.claim.claimInfo.upload.files": "Datei",
	"createClaim.claim.claimInfo.claimant.title": "Anspruchsteller",
	"createClaim.claim.causeOfDamage.label":
		"Schadenhergang laut Sicht des Anspruchstellers",
	"createClaim.claim.statement.label":
		"Stellungnahme des Verwalter zum Schadenhergang (Schuldfrage)",
	"createClaim.claim.whatIsDamaged.label": "Was wurde beschädigt?",
	// payout
	"createClaim.payout.title": "Angaben zum Empfänger der Schadenauszahlung",
	"createClaim.payout.subtitle": "Kontoinhaber",
	"createClaim.payout.payoutName": "Vor- und Nachname / Firmenname",
	"createClaim.payout.iban.label": "IBAN",
	"createClaim.payout.iban.placeholder": "DE21301204000000015228",
	"createClaim.payout.bankName.label": "Name der Bank",
	"createClaim.payout.bankName.placeholder": "VR Bank Westküste",
	"createClaim.payout.bic.label": "BIC",
	"createClaim.payout.bic.placeholder": "GENODEG2MUH",
	"createClaim.payout.preTaxDeduction": "Zum Vorsteuerabzug berechtigt?",
	"createClaim.payout.preTaxDeductionAmount.label":
		"Bitte geben Sie den Prozentsatz des Vorsteuerabzuges inkl. Nachkommastellen an.",
	"createClaim.payout.preTaxDeductionAmount.placeholder": "19,00 %",
} as const;
