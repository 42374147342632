export default {
	"userData.heading": "Stammdaten ändern",
	"userData.confirm": "Änderungen bestätigen ({count})",
	"userData.close": "Schließen",
	"userData.birthday": "Geburtsdatum",
	"userData.birthDate": "Geburtsdatum",
	"userData.gender": "Geschlecht",
	"userData.heading.nameAndBirthday": "Name, Geburtsdatum",
	"userData.name": "Vor- und Nachname",
	"userData.firstName": "Vorname",
	"userData.lastName": "Nachname",
	"userData.heading.address": "Anschrift",
	"userData.address": "Aktuelle Hauptadresse",
	"userData.street": "Straße",
	"userData.houseNumber": "Hausnummer",
	"userData.heading.phone": "Telefonnummern",
	"userData.phone": "Telefon",
	"userData.heading.email": "E-Mail",
	"userData.email": "E-Mail-Adresse",
	"userData.city": "Ort",
	"userData.zipCode": "PLZ",
	"userData.iban": "IBAN",
	"userData.success.title": "Ihre Änderungen wurden erfolgreich umgesetzt",
	"userData.loading": "Ihre Daten werden verarbeitet",
	"userData.success.text":
		"<wrapper><p>Wenn Sie Fragen haben sollten, dann melden Sie sich gerne unter:</p>{links}</wrapper>",
	"userData.success.phone.href": "tel:00494035914504",
	"userData.success.phone.label": "Tel.: + 49 40 35914-504",
	"userData.success.email.href": "mailto:heilwesen@funk-experts.de",
	"userData.success.email.label": "E-Mail: heilwesen@funk-experts.de",
	"userData.salutation": "Anrede",
	"userData.heading.businessAddress": "Aussteller",
	"userData.business": "Firma",
	"userData.heading.contactName": "Ansprechpartner",
} as const;
