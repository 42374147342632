export default {
	"setPassword.title": "Ein neues Kennwort erstellen",
	"setPassword.title.success": "Passwort erfolgreich geändert",
	"setPassword.content":
		"<p>Ihr Kennwort muss sich von den bisher verwendeten Kennwörtern unterscheiden.</p>",
	"setPassword.content.success":
		"<p>Sie können sich nun mit Ihrem neuen Passwort anmelden.</p>",
	"setPassword.confirm.label": "Kennwort wiederholen",
	"setPassword.confirm.error": "Die beiden Passwörter müssen übereinstimmen.",
	"setPassword.goToLogin": "Zum Login",
	"setPassword.api.error":
		"Das Passwort konnte nicht zurückgsetzt werden. Bitte versuchen Sie es noch einmal.",
	"setPassword.submit.label": "Kennwort neu setzen",
	"setPassword.policy": `
		<policy>
			<rule>Das Passwort muss Zahlen enthalten</rule>
			<rule>Das Passwort muss Großbuchstaben enthalten</rule>
			<rule>Die Länge muss mehr als 8 Zeichen betragen</rule>
		</policy>`,
} as const;
