export default {
	"footer.socialTitle": "Finden Sie uns auch hier:",
	"social.instagram.title": "Zu unserem Instagram-Profil",
	"social.youtube.title": "Zu unserem YouTube-Profil",
	"social.linkedin.title": "Zu unserem LinkedIn-Profil",
	"footer.iso":
		"Funk erfüllt die Anforderungen für Qualitätsmanagement und Informationssicherheit nach ISO 9001 und ISO 27001.",
	"footer.copyright":
		"© Ein Unternehmen der Funk Gruppe GmbH, Hamburg. Alle Rechte vorbehalten.",
	"footer.quality": "Zertifizierte Qualität",
	"footer.defaultPhoneNumber": "+49 40 35914-0",
	"footer.defaultEmail": "welcome@funk-experts.de",
} as const;
