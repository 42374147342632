export default {
	"datatable.to": "bis",
	"datatable.of": "von",
	"datatable.itemsPerPage": "Zeilen pro Seite",
	"datatable.prevPage": "Vorherige Seite",
	"datatable.nextPage": "Nächste Seite",
	"datatable.selectAll": "Alle auswählen",
	"datatable.select": "wählen",
	"datatable.search": "Alle Spalten durchsuchen...",
	"datatable.selectedItems": "({selected}/{all}) ausgewählte Verträge",
	"datatable.documents": "Versicherungsrelevante Dokumente einsehen",
	"datatable.search.button": "Suchen",
} as const;
