export default {
	"newsletter.success.title": "Vielen Dank!",
	"newsletter.success.text":
		"Ihre E-Mail Adresse wurde erfolgreich eingetragen.",
	"newsletter.submit.label": "Senden",
	"newsletter.email.label": "E-Mail",
	"newsletter.email.placeholder": "Ihre E-Mail-Adresse",
	"newsletter.privacy":
		"Ihre Einwilligung in den Erhalt von E-Mails können Sie jederzeit - etwa durch Betätigen des Abmelde-Links in unserer Bestätigungs-Mail - widerrufen. Weitere Informationen zur Verarbeitung Ihrer personenbezogenen Daten, u.a. im Rahmen von Newsletterversand und -tracking, finden Sie in unserer <link>Datenschutzerklärung</link>.",
	"newsletter.privacyPolicy.url": "/datenschutz",
} as const;
