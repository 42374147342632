export default {
	"policy.list.title": "Ihre Versicherungsprodukte",
	"portal.dashboard.title": "Portal: Funk Experts",
	"portal.dashboard.greeting": "Hallo {name}!",
	"portal.dashboard.welcome":
		"Hier ist Ihr persönlicher Versicherungsbereich. Sie können Ihre Policen ansehen, ändern und natürlich einen Schaden melden.",
	"portal.dashboard": "Dashboard",
	"portal.dashboard.broker.wegs":
		"Wie möchten Sie Ihre WEGs policieren lassen?",
	"portal.dashboard.broker.manual.title": "Manuell",
	"portal.dashboard.broker.manual.description":
		"Ideal für die Eingabe von weniger als 5 Verträgen",
	"portal.dashboard.broker.automatic.title": "Automatisch",
	"portal.dashboard.broker.automatic.description":
		"Optimal für die Eingabe von mehr als 5 Verträgen",
	"portal.dashboard.broker.overview": "Ihre Übersicht",
	"broker.table.productName": "Versicherung",
	"broker.table.policyNumber": "Vertrag Nr.",
	"broker.table.factors": "Faktoren",
	"broker.table.premium": "Prämie <small>(Brutto p.A)</small>",
	"broker.table.updatedAt": "Letzte Änderung",
	"broker.table.expand": "Erweitern",
	"broker.table.objectId": "Objekt-ID",
} as const;
